<template>
  <div>
    <a-layout id="backend-layout">
      <a-layout-header class="layout-header">
        <div class="logo">
          <img v-if="official_display"
            src="~@/assets/images/logo1.png"
            alt="Ai团餐"
          />
          <div class="text">
            <h1>{{currentSchool.name}}</h1>
          </div>
        </div>
        <div class="user-info">
          <div class="avna">
            <a-avatar
              :size="24"
              :src="avatarUrl"
            />
            <span class="name">{{currentUser.name||currentUser.account}}</span>
          </div>
          <div
            class="exit"
            @click="clickLogout"
          >
            <icon-font type="icon-tuichu" />退出
          </div>
        </div>
      </a-layout-header>
      <a-layout class="meco">
        <a-layout-sider
          :trigger="null"
          collapsible
          v-model="collapsed"
          class="layout-left-menu"
        >
          <div class="menu">
            <a-menu
              mode="inline"
              :open-keys.sync="openKeys"
              :defaultSelectedKeys="[selectedKey]"
            >
              <!-- <a-menu-item key="1">
                <router-link to="">
                  <icon-font type="icon-jiankong" />
                  <span>监控大屏</span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="2">
                <router-link to="">
                  <icon-font type="icon-canting" />
                  <span>餐厅管理</span>
                </router-link>
              </a-menu-item> -->
              <a-menu-item key="NutritionMonitorIndex">
                <router-link :to="{name:'NutritionMonitorIndex'}">
                  <icon-font type="icon-roulei" />
                  <span>警员营养监测</span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="FoodSafetyDetect" v-if="false">
                <router-link :to="{name:'FoodSafetyDetect'}">
                  <icon-font type="icon-anquan" />
                  <span>食品安全监测</span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="FileManage" v-if="!isTeacher">
                <router-link :to="{name:'FileManage'}">
                  <icon-font type="icon-dangan" />
                  <span>档案管理</span>
                </router-link>
              </a-menu-item>
              <!-- <a-menu-item key="MealCardManage">
                <router-link :to="{name:'MealCardManage'}">
                  <icon-font type="icon-cardb" />
                  <span>饭卡管理</span>
                </router-link>
              </a-menu-item> -->
              <a-menu-item key="FoodSafetyTraced" v-if="!isTeacher">
                <router-link :to="{name: 'FoodSafetyTraced'}">
                  <icon-font type="icon-suyuan" />
                  <span>食安溯源</span>
                </router-link>
              </a-menu-item>
              <a-sub-menu key="subMenu" class="sub">
                <span slot="title">
                  <icon-font type="icon-shezhi" /><span>系统管理</span></span>
                <a-menu-item key="OrgUserManage" v-if="currentUser.isOrgAdmin">
                  <router-link :to="{name:'OrgUserManage'}">总局用户</router-link>
                </a-menu-item>
                 <a-menu-item key="SchoolUserManage" v-if="!isTeacher">
                  <router-link :to="{name:'SchoolUserManage'}">用户管理</router-link>
                </a-menu-item>
                <a-menu-item key="ClassManage" v-if="!isTeacher">
                  <router-link :to="{name:'ClassManage'}">部门管理</router-link>
                </a-menu-item>
                <a-menu-item key="ModifyPassword">
                  <router-link :to="{name:'ModifyPassword'}">修改密码</router-link>
                </a-menu-item>
              </a-sub-menu>
            </a-menu>
          </div>
        </a-layout-sider>
        <a-layout-content class="layout-content">
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
      <a-layout-footer class="layout-footer">
        <footer-component></footer-component>
      </a-layout-footer>
    </a-layout>
    <public-confirm-modal @onConfirm="logout"></public-confirm-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import IconFont from '@/components/Iconfont'
import footerComponent from '@/components/Footer'
import publicConfirmModal from '@/components/common/PublicConfirmModal'
import exitImg from '@/assets/images/icon-exit.png'
import apis from '@/business/apis'
import avatarUrl from '@/assets/images/default-avatar.jpg'
export default {
  inject: ['eventBus'],
  components: {
    IconFont,
    footerComponent,
    publicConfirmModal
  },
  data () {
    return {
      avatarUrl,
      selectedKey: 'FileManage',
      collapsed: false,
      openKeys: ['subMenu']
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool',
      'currentOrg'
    ]),
    ...mapState('user', [
      'currentUser'
    ]),
    ...mapGetters('user', [
      'isTeacher'
    ]),
    ...mapGetters('school', [
      'official_display'
    ])
  },
  methods: {
    ...mapActions('user', [
      'saveCurrUser'
    ]),
    ...mapActions('school', [
      'loadSchoolTypeList'
    ]),
    async logout () {
      if (this.currentUser.isOrgAdmin) {
        await this.$http.post(apis.orgAdmin.logout)
        this.saveCurrUser(null)
        this.$router.replace({ path: `/org/${this.currentOrg.code}` })
      } else {
        await this.$http.post(apis.schoolAdmin.logout)
        this.saveCurrUser(null)
        this.$router.replace({ path: `/police/${this.currentSchool.code}` })
      }
    },
    clickLogout () {
      this.eventBus.$emit('show-commonconfirm-modal', '确定退出吗？', null, exitImg)
    }
  },
  async created () {
    if (this.$router.currentRoute) {
      this.selectedKey = this.$router.currentRoute.name
    }
    await this.loadSchoolTypeList()
  }
}
</script>
<style lang="less" scoped>
#backend-layout {
  background-color: #f6f8f9;
  .layout-header {
    background: #fff;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      min-width: 13%;
      padding: 0 20px 0 12px;
      display: flex;
      align-items: center;
      img {
        width: 28px;
        margin-top: -4px;
      }
      .text {
        line-height: 1.4;
        margin-left: 7px;
        h1 {
          margin: 0;
          font-size: 18px;
        }
        .version {
          margin-top: -3px;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }
    .user-info {
      font-size: 15px;
      display: flex;
      align-items: center;
      > div {
        position: relative;
        padding: 0 24px;
        &::after {
          content: "";
          position: absolute;
          z-index: 1;
          right: 0;
          top: 24px;
          bottom: 24px;
          width: 1px;
          background: #dedee4;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
      .qrcode {
        .anticon {
          margin-right: 4px;
        }
      }
      .avna {
        display: flex;
        align-items: center;
        .name {
          max-width: 120px;
          margin-left: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .exit {
        .anticon {
          margin-right: 4px;
        }
      }
    }
  }
  .meco {
    .layout-left-menu {
      background-color: #f6f8f9;
      border-right: 1px solid #e7e7eb;
      .menu {
        .ant-menu {
          background: transparent;
          border-right: none;
          .ant-menu-item {
            height: 48px;
            margin: 0;
            .anticon {
              margin-right: 8px;
            }
            & a {
              color: #353535;
              line-height: 48px;
            }
            &:hover {
              background-color: #eff1f4;
            }
          }
          .ant-menu-submenu-title {
            .anticon {
              margin-right: 8px;
            }
          }
          .ant-menu-item-selected {
            background: linear-gradient(40deg, #6297f8, #4283fc);
            & a,
            a:hover {
              color: #ffffff;
            }
            &::after {
              display: none;
            }
          }
          .ant-menu-submenu-selected {
            color: #353535;
          }
          .ant-menu-sub {
            a {
              margin-left: 4px;
              font-weight: normal;
            }
          }
        }
        .sub{
          .ant-menu {
            background: transparent!important;
          }
        }
      }
    }
    .layout-content {
      padding: 0 24px;
      background-color: #f6f8f9;
      min-height: 100vh;
    }
  }
  .layout-footer {
    background-color: #f6f8f9;
    .footer {
      padding: 0;
    }
  }
}
</style>
